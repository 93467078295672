import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserInterface {
    userId: string;
    memberId: number;
    type: string;
}

interface GroupListInterface {
    groupId: string;
    groupName: string;
    members: UserInterface[];
}
interface PermissionInterface {
    presetActive: boolean | null;
    seaLingActive: boolean | null;
}
interface GroupReducerInterface {
    groupList: GroupListInterface[];
    selectedGroup: GroupListInterface | null;
    permission: PermissionInterface;
}

const initialState: GroupReducerInterface = {
    groupList: [],
    selectedGroup: null,
    permission: {
        presetActive: true,
        seaLingActive: true,
    },
};

const GroupReducerSlice = createSlice({
    name: 'groupReducer',
    initialState,
    reducers: {
        saveGroupList: (state, action: PayloadAction<GroupListInterface[]>) => {
            state.groupList = action.payload;
        },
        saveSelectedGroup: (state, action: PayloadAction<GroupListInterface>) => {
            state.selectedGroup = action.payload;
        },
        setPermission: (state, action: PayloadAction<PermissionInterface>) => {
            state.permission= action.payload;
        },
    },
});

export const { saveGroupList, saveSelectedGroup, setPermission } = GroupReducerSlice.actions;

export default GroupReducerSlice.reducer;
