import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { Button, Icon, IconButton, TextField } from '@mui/material';
import Chip from '../../../../Foundation/Chip';
import { editIosAppName } from '../../../../../services/sealing/ios';
import DropdownMenu from '../../../../utils/dropDownMenu';
import { showToast } from "../../../../utils/notifications";

interface IOSCardInterface {
    app_name: string;
    package_name: string;
    service_onoff: string;
    service_type?: string;
    service_mod_user_id?: string;
    service_mod_date?: string;
    hideTag?: boolean;
    onToggleSwitch: (type: string, params: any, cardDetails: any) => void;
    handleSelectApp?: (app: any) => void;
}

const IOSServiceCard = (props: IOSCardInterface) => {
    const { t } = useTranslation();
    const [checked, setChecked] = useState(props.service_onoff === 'Y');
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
   
    const isOwner = authContext?.role === 'Owner' || authContext?.role === 'SubOwner';
    const isBetaAccount = authContext.is_beta_account;

    const [tempAppName, setTempAppName] = useState(props.app_name);
    const [tempEdit, setTempEdit] = useState(false);
    
    const starteditIosAppName = () => {
            setTempEdit(true);
    };
    const updateAppName = async (props: IOSCardInterface, tempAppName: string) => {           
        const response = await editIosAppName({bundleId:props.package_name, updatedAppName:tempAppName,token:authContext.token});
            if (response?.result?.code === "0000") {
                setTempEdit(false);
                showToast("success", t("app_name_updated_successfully"));
                window.location.reload(); // Reload the page to reflect the updated app name
            } else if (response?.result?.code) {
                showToast(
                "error",
                t(
                    `RCM-${response?.result?.code}`,
                    response?.result?.message
                    ? response?.result?.message
                    : t("error_occurred")
                ) as string
                );
            } else {
                showToast("error", t("error_occurred"));
            }
    };
    
    

    useEffect(() => {
        setChecked(props.service_onoff === 'Y');
    }, [props.service_onoff]);

    const onServiceChange = (e: any) => {
        const params = {
            api_ver: '4',
            platform: 'ios',
            token: authContext.token,
            package_name: props.package_name,
            status: e.target.checked ? 'Y' : 'N',
        };

        props.onToggleSwitch('service', params, { ...props });
    };

    const handleMenuChange = (type: string) => {
        if (props.handleSelectApp) {
            props.handleSelectApp({ ...props });
        }
    };
    const tagName = props.service_type?.includes('HYBRID') ? 'Hybrid' : 'Native';

    return (
        <>
            <Card key={props.package_name} className="w-full max-w-full lg:max-w-[316px]">
                <CardHeader title={
                    <div className="flex items-center justify-between flex-none">
                        {tempEdit ? (
                           <div className="flex items-center">
                           <IconButton
                               className="ml-2"
                               title={t('save')}
                               onClick={() => updateAppName(props, tempAppName)}
                           >
                               <Icon sx={{ fontSize: 15 }} component={CheckIcon} />
                           </IconButton>
                           <IconButton className="ml-2"
                               title={t('cancel')} onClick={() => setTempEdit(false)}>
                           <Icon sx={{ fontSize: 15 }} component={ClearIcon} />
                           </IconButton>
                           <TextField
                               className="w-40"
                               value={tempAppName}
                               fullWidth
                               onChange={(e) => setTempAppName(e.target.value)}
                           />
                       </div>
                        ) : (
                            <span className="text-base font-semibold truncate text">
                                {(isOwner)?<IconButton onClick={() => setTempEdit(true)}>
                                    <Icon sx={{ fontSize: 15 }} component={EditIcon} />
                                 </IconButton>: null}
                                <span>{props.app_name}</span>
                            </span>
                        )}
                        {props.service_type && props.hideTag !== true && <Chip color={tagName.toLowerCase()} className="ml-2" label={t(tagName.toLowerCase().replaceAll(' ', '_'))} />}
                        {isBetaAccount && <DropdownMenu menuOptions={[{ value: 'settings', label: 'settings' }]} handleMenuChange={handleMenuChange} />}
                    </div>
                } />
                <CardContent>
                    <p className="flex justify-between text-sm">
                        <span className="mr-2 font-semibold whitespace-nowrap" style={{ wordBreak: 'keep-all' }}>
                            {t('bundle_id')}{' '}
                        </span>
                        <Tooltip title={props.package_name}>
                            <span className="truncate">{props.package_name}</span>
                        </Tooltip>
                    </p>
                    <p className="flex justify-between mt-6">
                        <span className="mr-2 font-semibold whitespace-nowrap">{t('updated_by')}</span>
                        <Tooltip title={props.service_mod_user_id}>
                            <span className="truncate">{props.service_mod_user_id}</span>
                        </Tooltip>
                    </p>
                    <p className="flex justify-between mt-6">
                        <span className="mr-2 font-semibold whitespace-nowrap">{t('updated_on')}</span>
                        <span>{props.service_mod_date}</span>
                    </p>
                    <Tooltip title={t('only_owner_access')}>
                        <p className="flex justify-between mt-3">
                            <span className="flex items-center mr-2 font-semibold whitespace-nowrap">{t('service')} </span>

                            <Switch checked={checked} onChange={onServiceChange} inputProps={{ 'aria-label': 'controlled' }} disabled={!isOwner} />
                        </p>
                    </Tooltip>
                </CardContent>
            </Card>
        </>
    );
};

export default IOSServiceCard;
