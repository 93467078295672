import React, { useEffect, useState, useCallback } from 'react';
import { downloadSealedFile, getAOSSealingHistory, getHybridAOSSealingHistory, getLogDetails, uploadSealedApp } from '../../../../services/sealing/android';
import { RootState } from '../../../../store';
import { useSelector } from 'react-redux';
import moment from 'moment';
import LoadMask from '../../../utils/loadmask';
import { showDialog } from '../../../utils/notifications';
import DownloadModal from './download';
import { filterBy } from '../../../../utility/filtering';
import { useTranslation } from 'react-i18next';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import HistoryTable from './HistoryTable';
import DragnDrop from '../../../utils/dragndrop';
import CloseIcon from '@mui/icons-material/Close';
import JSZip from 'jszip';
import { EditJson, FilterFields } from './HistoryFunction';
import CachedIcon from '@mui/icons-material/Cached';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const disablePast = moment().subtract(1, 'year');
const date = moment().subtract(1, 'M');
const acceptFiles = {
    'application/zip': ['.zip'],
    'application/vnd.android.package-archive': ['.apk'],
    'application/x-apk-bundle': ['.aab'],
};

const AndroidHistoryInfo = () => {
    const { t } = useTranslation();
    const [filterText, setFilterText] = useState('');
    const [showMask, setShowMask] = useState<any>(null);
    const [file] = useState<any>(null);
    const [showDownloadModal, setShowDownloadModal] = useState<any>(false);
    const [historyData, setHistoryData] = useState<any[] | null>(null);
    const [filters, setFilters] = useState<any>([]);
    const [open, setOpen] = React.useState(false);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const userDetails = useSelector((state: RootState) => state.mainReducer.userDetails);
    const region = userDetails?.default_service_region;
    const [uploadedApp, setUploadedApp] = useState<any>(null);
    const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(null);
    const isBetaAccount = authContext.is_beta_account;

    const fetchAppData = useCallback(
        async (date: moment.Moment, toDate?: moment.Moment) => {
            const params: any = {
                req_source: 'ADC_AOS_HYBRID',
                sealed_date_from_view: date.format('YYYY/MM/DD'),
                sealed_date_from: date.format('YYYYMMDD'),
                service_type: 'ALL',
                token: authContext.token,
            };
            if (toDate) params['sealed_date_to'] = toDate.format('YYYYMMDD');
            setShowMask({ msg: t('fetching_sealing_history') });
            const response = await getAOSSealingHistory({ ...params }, region);
            const hybridResponse = await getHybridAOSSealingHistory({ ...params }, region);
            setShowMask(null);
            let data = [];
            if (response?.result?.code === '0000' && Array.isArray(response?.sealingHistoryList) && response?.sealingHistoryList.length > 1) {
                data = response.sealingHistoryList;
                data.pop();
            }
            if (hybridResponse?.result?.code === '0000' && Array.isArray(hybridResponse?.sealingHistoryList) && hybridResponse?.sealingHistoryList.length > 1) {
                let hybridData = hybridResponse.sealingHistoryList;
                hybridData.pop();
                hybridData = hybridData.map((e: any) => ({
                    ...e,
                    sealed_date: e.start_time_GMT,
                    status_code: e.status,
                    service_type: 'HYBRID_AOS',
                    file_type: 'zip',
                }));
                data = data.concat(hybridData);
            }
            data = data.sort((a: any, b: any) => b.sealed_date - a.sealed_date);
            data = data.map((e: any, index: number) => ({
                ...e,
                id: index,
            }));

            setHistoryData(data);
        },
        [authContext.token, region, t],
    );

    useEffect(() => {
        if (historyData === null) {
            FilterFields[0]['disablePast'] = disablePast;
            setHistoryData([]);
            fetchAppData(date);
        }
    }, []);

    const onSearchClick = (e: any) => {
        const value = e.target.value;
        let filter: any = [];
        if (value) {
            filter = {
                logic: 'or',
                filters: [
                    { field: 'user_id', value: value, operator: 'contains' },
                    { field: 'package_name', value: value, operator: 'contains' },
                    { field: 'app_name', value: value, operator: 'contains' },
                ],
            };
        }
        setFilterText(value);
        setFilters(filter);
    };

    const onDownloadClick = async (record: any) => {
        if (record.file_type === 'zip') {
            const params = {
                token: authContext.token,
                packId: record.pack_id,
            };
            downloadSealedFile(params, region);
        } else setShowDownloadModal(record);
    };

    const onViewErrorClick = async (record: any) => {
        const { pack_id, user_id } = record;
        const params = {
            pack_id,
            user_id,
            token: authContext.token,
        };
        const response = record.file_type === 'zip' ? `${record.error_code} - ${record.error_message}` : await getLogDetails(params, region);
        showDialog({
            title: t('sealing_error_message'),
            content: (
                <div>
                    <div className="break-words" dangerouslySetInnerHTML={{ __html: response }}></div>
                </div>
            ),
            onOk: () => {},
            cancelButtonProps: { hidden: true },
            okText: t('close'),
        });
    };

    const handleClearClick = () => {
        setFilterText('');
        setFilters([]);
    };

    const onMenuClick = (key: string, row: any) => {
        switch (key) {
            case 'view_errors':
                onViewErrorClick(row);
                break;
            case 'download':
                onDownloadClick(row);
                break;
            default:
                break;
        }
    };

    const getSealingOptions = async (id: string, filename: string) => {
        const params = {
            token: authContext.token,
            packId: id,
        };
        const response = await uploadSealedApp(params);
        if (response === false || response.data === null) {
            showDialog({
                title: t('error'),
                content: t('sealing_option_checker_error'),
                onOk: () => {},
                cancelButtonProps: { hidden: true },
                okText: t('close'),
            });
            handleClose();
        }
        if (response.result.code === '0000') {
            let data = EditJson(response.data, filename);
            setUploadedApp([data]);
            handleClose();
        }
    };

    const extractUUID = (content: string): string | null => {
        const uuidRegex = /[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}/g;
        const match = content.match(uuidRegex);
        return match ? match[0].toUpperCase() : null;
    };

    const onFileUpload = async (files: FileList | null) => {
        const file = files?.[0];
        if (file) {
            try {
                const filename = file.name;
                const zip = new JSZip();
                const content = await zip.loadAsync(file);
                const filePath = file.name.endsWith('.aab') ? 'base/assets/AppSealing/si' : 'assets/AppSealing/si';
                const specificFile = content.file(filePath);
                if (specificFile) {
                    const fileContent: Uint8Array = await specificFile.async('uint8array');
                    const textDecoder = new TextDecoder();
                    const decodedContent: string = textDecoder.decode(fileContent);
                    const extractedUUID = extractUUID(decodedContent);
                    getSealingOptions(extractedUUID || '', filename || '');
                } else {
                    showDialog({
                        title: t('error'),
                        content: t('pack_id_is_missing'),
                        onOk: () => {},
                        cancelButtonProps: { hidden: true },
                        okText: t('close'),
                    });
                }
            } catch (error) {
                console.error('Error reading file:', error);
            }
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClearApp = () => {
        setUploadedApp(null);
    };

    return (
        <div className="flex flex-col h-full">
            <DownloadModal visible={showDownloadModal ? true : false} data={showDownloadModal} onCancel={() => setShowDownloadModal(false)} />
            {showMask && <LoadMask msg={showMask.msg} />}
            <div className="flex flex-wrap gap-6 mb-8 lg:flex-nowrap">
                <div className="w-full lg:max-w-[316px] text-2xl font-medium">{t('historical_information')}</div>
                <div className="flex justify-end w-full gap-4 lg:mt-0">
                    {isBetaAccount && (
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label={t('from_date')}
                                value={selectedDate}
                                onChange={(newValue) => {
                                    if (newValue && newValue.isValid()) {
                                        setSelectedDate(newValue);
                                        fetchAppData(newValue);
                                    }
                                }}
                                maxDate={moment()}
                                minDate={moment().subtract(1, 'year')}
                                views={['day']}
                                openTo="day"
                                format="YYYY-MM-DD"
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        sx: { width: '260px' },
                                        InputLabelProps: {
                                            shrink: true,
                                        },
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    )}
                    <div className="max-w-[500px] w-full">
                        <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            value={filterText}
                            onChange={onSearchClick}
                            label={t('search')}
                            placeholder={t('search_app_name_or_user_id')}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {filterText && (
                                            <IconButton aria-label="clear text" onClick={handleClearClick} size="small" edge="end">
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="flex">
                        <Button variant="outlined" color="secondary" className="mr-3 h-[37px] w-[37px] min-w-10 p-0" onClick={handleClearApp}>
                            <CachedIcon fontSize="small" />
                        </Button>
                        <Tooltip placement="top" title={t('you_can_check_preset')} arrow>
                            <Button variant="outlined" className="h-[37px] w-[37px] min-w-10 p-0" onClick={handleOpen}>
                                APP
                            </Button>
                        </Tooltip>
                        <Dialog
                            open={open}
                            onClose={() => handleClose()}
                            PaperProps={{
                                style: { width: '80%', maxWidth: '650px' },
                            }}
                        >
                            <DialogTitle className="flex justify-between pb-8">
                                <div>
                                    <Typography variant="h1" className="w-full font-medium">
                                        {t('you_can_check_sealing_options')}
                                    </Typography>
                                    <p className="font-normal text-base text-[#636466]">{t('you_can_check_sealing_options_subtitle')}</p>
                                </div>

                                <CloseIcon onClick={handleClose} className="cursor-pointer text-[#a6a6a7]" />
                            </DialogTitle>

                            <DialogContent>
                                <DragnDrop
                                    color="primary"
                                    appFile={file ? file : []}
                                    onChange={(files: FileList | null) => onFileUpload(files)}
                                    maxFiles={1}
                                    accept={acceptFiles}
                                    title={t('click_or_drag_application_file')}
                                    subtitle={t('supports_apk_or_appbundle_type_for_application_sealing')}
                                />
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
            </div>
            <div className="flex-grow mt-4 overflow-auto">
                {uploadedApp ? <HistoryTable onMenuClick={onMenuClick} data={uploadedApp} expanded={true} /> : <HistoryTable onMenuClick={onMenuClick} data={filterBy(historyData || [], filters)} />}
            </div>
        </div>
    );
};

export default AndroidHistoryInfo;
