import { decode } from '../utility/encoding';
// config.ts
export const getEnvironment = () => {
    const hostname = window.location.hostname;

    if (hostname.includes('dev') || hostname.includes('develop')) {
        return 'dev';
    } else if (hostname.includes('tst')) {
        return 'test';
    } else if (hostname.includes('localhost')) {
        return 'local';
    }
    return 'production';
};

export const getApiUrls = () => {
    const env = getEnvironment();

    let apiUrl, inApiUrl;

    switch (env) {
        case 'dev':
            apiUrl = decode(process.env.REACT_APP_DEV_API_URL || '');
            inApiUrl = decode(process.env.REACT_APP_DEV_IN_API_URL || '');
            break;
        case 'test':
            apiUrl = decode(process.env.REACT_APP_TST_API_URL || '');
            inApiUrl = decode(process.env.REACT_APP_TST_IN_API_URL || '');
            break;
        case 'local':
            apiUrl = decode(process.env.REACT_APP_DEV_API_URL || '');
            inApiUrl = decode(process.env.REACT_APP_DEV_IN_API_URL || '');
            break;
        default:
            apiUrl = decode(process.env.REACT_APP_API_URL || '');
            inApiUrl = decode(process.env.REACT_APP_IN_API_URL || '');
    }

    return { apiUrl, inApiUrl };
};

export const { apiUrl: API_URL, inApiUrl: IN_API_URL } = getApiUrls();
