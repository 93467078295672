import i18n from 'i18next';
import { SetStateAction } from 'react';
import { Delete, FilePost, Get, Post, Put } from '../..';
import { errorDialog, showToast } from '../../../components/utils/notifications';
import { createNewAndroidApp, KeyStoreInterface, SealingInfoInterface, SealingInterface, SealingStatusInterface, UpdateAndroidAppNameInterface, UploadKeyStoreInterface } from '../../../type/api/sealing';
import { AppURL, getRegionalURL, RegionalURL } from '../../../utility/AppURL';

export const getServiceables = async (params: { token: string }, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token } = params;
    let urlParams = new URLSearchParams();
    urlParams.append('token', token);

    const response = await Post(getRegionalURL(RegionalURL.serviceables, region), urlParams)
        .then((res) => res.json())
        .catch(() => {});
    const hybridResponse = await Post(getRegionalURL(RegionalURL.hybridserviceables, region), urlParams)
        .then((res) => res.json())
        .catch(() => {});
    let errors: Array<any> = [];
    if (response?.result?.code === '6037') errors.push('androidpay');
    else if (response?.result?.code !== '0000') errors.push('android');
    if (hybridResponse?.result?.code === '6037') errors.push('hybridpay');
    else if (hybridResponse?.result?.code !== '0000') errors.push('hybrid');

    let serviceVersions: Array<any> = [];
    if (Array.isArray(response?.serviceVersionList)) {
        serviceVersions = serviceVersions.concat(response.serviceVersionList.map((e: any) => ({ ...e, type: 'native' })));
        serviceVersions.pop();
    }
    if (Array.isArray(hybridResponse?.serviceVersionList)) {
        serviceVersions = serviceVersions.concat(hybridResponse.serviceVersionList.map((e: any) => ({ ...e, type: 'hybrid' })));
        serviceVersions.pop();
    }

    let sealingAllowWorkProfileList: Array<any> = [];
    if (Array.isArray(response?.sealingAllowWorkProfileList)) {
        sealingAllowWorkProfileList = sealingAllowWorkProfileList.concat(response.sealingAllowWorkProfileList.map((e: any) => ({ ...e, type: 'native' })));
    }
    if (Array.isArray(hybridResponse?.sealingAllowWorkProfileList)) {
        sealingAllowWorkProfileList = sealingAllowWorkProfileList.concat(hybridResponse.sealingAllowWorkProfileList.map((e: any) => ({ ...e, type: 'hybrid' })));
    }

    let sealingAllowEmulList: Array<any> = [];
    if (Array.isArray(response?.sealingAllowEmulList)) {
        sealingAllowEmulList = sealingAllowEmulList.concat(response.sealingAllowEmulList.map((e: any) => ({ ...e, type: 'native' })));
    }
    if (Array.isArray(hybridResponse?.sealingAllowEmulList)) {
        sealingAllowEmulList = sealingAllowEmulList.concat(hybridResponse.sealingAllowEmulList.map((e: any) => ({ ...e, type: 'hybrid' })));
    }
    return { serviceVersions, sealingAllowEmulList, sealingAllowWorkProfileList, errors };
};

export const getAOSSealingHistory = async (params: SealingInfoInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { sealed_date_from, sealed_date_from_view, token, service_type, pack_id, sealed_date_to } = params;
    let urlParams = new URLSearchParams();
    urlParams.append('sealed_date_from', sealed_date_from);
    urlParams.append('sealed_date_from_view', sealed_date_from_view);
    urlParams.append('service_type', service_type);
    urlParams.append('token', token);
    if (sealed_date_to) urlParams.append('sealed_date_to', sealed_date_to);
    if (pack_id) urlParams.append('pack_id', pack_id);

    const response = await Post(getRegionalURL(RegionalURL.sealinginfo, region), urlParams)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const getHybridAOSSealingHistory = async (params: SealingInfoInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { sealed_date_from, sealed_date_from_view, token, service_type, packId, sealed_date_to } = params;
    let urlParams = new URLSearchParams();
    urlParams.append('sealed_date_from', sealed_date_from);
    urlParams.append('sealed_date_from_view', sealed_date_from_view);
    urlParams.append('service_type', service_type);
    urlParams.append('token', token);
    if (sealed_date_to) urlParams.append('sealed_date_to', sealed_date_to);
    if (packId) urlParams.append('packId', packId);

    const response = await Post(getRegionalURL(RegionalURL.sealingHistory, region), urlParams)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const getLogDetails = async (params: { token: string; pack_id: string; user_id: string }, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { pack_id, token, user_id } = params;
    let urlParams = new URLSearchParams();
    urlParams.append('pack_id', pack_id);
    urlParams.append('token', token);
    urlParams.append('user_id', user_id);

    const response = await Post(getRegionalURL(RegionalURL.viewlogpath, region), urlParams)
        .then((res) => res.json())
        .catch(() => {});

    let log = '';
    if (response?.result?.code === '0000' && response?.sealingView?.log_path) {
        let logParams = new URLSearchParams();
        logParams.append('log_path', response.sealingView.log_path);
        logParams.append('member_type', response.sealingView.member_type);
        logParams.append('pack_id', pack_id);

        const logResponse = await Post(getRegionalURL(RegionalURL.viewlog, region), logParams, {}, true)
            .then((res) => res.text())
            .catch(() => {});
        if (logResponse) log = logResponse;
    } else {
        log = response?.result?.message;
    }
    return log;
};

export const sealingAvailable = async (params: { token: string }, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token } = params;
    let urlParams = new URLSearchParams();
    urlParams.append('token', token);

    const response = await Post(getRegionalURL(RegionalURL.sealingavailable, region), urlParams)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const requestSealing = async (params: SealingInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { args, token, userID, apkfile, html5file } = params;
    let urlParams = new FormData();

    if (apkfile) urlParams.append('apkfile', apkfile);
    if (html5file) urlParams.append('html5file', html5file);
    urlParams.append('userID', userID);
    urlParams.append('token', token);
    urlParams.append('args', JSON.stringify(args));

    const header = {
        'As-Token': params.token,
    };
    const url = getRegionalURL(args.service_type === 'JS_ONLY' ? RegionalURL.requesthtmlsealing : RegionalURL.requestsealing, region);
    const response = await FilePost(url, urlParams, header)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const checkSealingStatus = async (params: SealingStatusInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, pack_id, extension, sealed_date_from, sealed_date_from_view, service_type } = params;
    let urlParams = new FormData();
    if (extension === 'zip') urlParams.append('packId', pack_id);
    else urlParams.append('pack_id', pack_id);

    urlParams.append('sealed_date_from', sealed_date_from);
    urlParams.append('sealed_date_from_view', sealed_date_from_view);
    urlParams.append('service_type', service_type);
    urlParams.append('token', token);

    const url = getRegionalURL(extension === 'zip' ? RegionalURL.checkhybridsealingstatus : RegionalURL.checksealingstatus, region);
    const response = await FilePost(url, urlParams)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const getKeyStoreInfo = async (params: KeyStoreInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, account_status, account_type, auth_key, role, user_status } = params;
    let urlParams = new URLSearchParams();

    urlParams.append('account_status', account_status);
    urlParams.append('account_type', account_type);
    urlParams.append('auth_key', auth_key);
    urlParams.append('role', role);
    urlParams.append('token', token);
    urlParams.append('user_status', user_status);
    if (params.packageName) {
        urlParams.append('packageName', params.packageName);
    }

    const response = await Post(getRegionalURL(RegionalURL.selectkeystoreinfo, region), urlParams)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const uploadKeyStore = async (params: UploadKeyStoreInterface, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, keystoreFile, ksPass, keyPass, alias } = params;
    let urlParams = new FormData();

    urlParams.append('keystoreFile', keystoreFile);
    urlParams.append('alias', alias);
    urlParams.append('ksPass', ksPass);
    urlParams.append('keyPass', keyPass);
    urlParams.append('token', token);

    if (params.packageName) {
        urlParams.append('packageName', params.packageName);
    }

    const response = await FilePost(getRegionalURL(RegionalURL.uploadkeystore, region), urlParams)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const deleteKeyStore = async (params: { token: string; keystoreFileName: string; alias: string; packageName?: string }, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, keystoreFileName, alias } = params;
    let urlParams = new FormData();

    urlParams.append('keystoreFileName', keystoreFileName);
    urlParams.append('alias', alias);
    urlParams.append('token', token);
    if (params.packageName) {
        urlParams.append('packageName', params.packageName);
    }
    const response = await FilePost(getRegionalURL(RegionalURL.deleteKeystore, region), urlParams)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

const checkPopupBlocked = async (): Promise<boolean> => {
    const newTab = window.open('about:blank', '_blank');

    if (!newTab || newTab.closed || typeof newTab.closed === 'undefined') {
        return true;
    }

    newTab.close();
    return false;
};

export const downloadSealedFile = async (params: { token: string; packId: string }, region: 'AWS_TOKYO' | 'AWS_MUMBAI') => {
    const { token, packId } = params;
    const url = getRegionalURL(`${RegionalURL.downloadSealedFile}?packId=${packId}&token=${token}`, region);
    const isPopupBlocked = await checkPopupBlocked();
    if (isPopupBlocked) {
        onPopupError(url);
        return;
    }

    const download = window.open(url, '_blank');
    if (download) {
        download.focus();
    } else {
        onPopupError(url);
    }
};

async function checkDownloadable(url: string) {
    try {
        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors', 
        });

        if (response.ok) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
}

export const downloadSealedApp = async (params: { token: string; download_id: string }, region: 'AWS_TOKYO' | 'AWS_MUMBAI', setDownloadSuccess?: React.Dispatch<SetStateAction<boolean>>) => {
    const { token, download_id } = params;
    const url = getRegionalURL(`${RegionalURL.downloadsealedapk}?download_id=${download_id}&token=${token}`, region);
    const isPopupBlocked = await checkPopupBlocked();
    if (isPopupBlocked) {
        onPopupError(url);
        return;
    }

    const isDownloadable = await checkDownloadable(url);
    if (isDownloadable) {
        const download = window.open(url, '_blank');
        if (download) {
            download.focus();
            if (setDownloadSuccess) setDownloadSuccess(true);
        } else {
            onPopupError(url);
        }
    } else {
        onDownloadError();
    }
};

export const downloadSealedSignedApp = async (
    params: { token: string; download_id: string; app_signing: string },
    region: 'AWS_TOKYO' | 'AWS_MUMBAI',
    showLoadMask: React.Dispatch<SetStateAction<boolean>>,
    setDownloadSuccess?: React.Dispatch<SetStateAction<boolean>>,
) => {
    const { token, download_id, app_signing } = params;
    const urlDownload = getRegionalURL(`${RegionalURL.downloadPreparedsealedsignedapp}?download_id=${download_id}&token=${token}&app_signing=${app_signing}`, region);
    // window.open(url, '_blank');
    let reqUrlParams = new URLSearchParams();
    reqUrlParams.append('token', token);
    reqUrlParams.append('download_id', download_id);
    reqUrlParams.append('app_signing', app_signing);

    const reqResponse = await Post(getRegionalURL(RegionalURL.requestDownloadsealedsignedapp, region), reqUrlParams)
        .then((res) => res.json())
        .catch(() => {
            showLoadMask(false);
            errorDialog({
                title: i18n.t('error'),
                content: i18n.t('request_download_error'),
                okText: i18n.t('ok'),
            });
        });

    if (reqResponse.code === '10006' || reqResponse.result.code === '6041') {
        const msg = i18n.t(`SCM-${reqResponse.code ? '10006' : '6041'}`);
        errorDialog({
            title: i18n.t('error'),
            content: msg,
            okText: i18n.t('ok'),
        });
        showLoadMask(false);
    }
    // unexpected error
    if (reqResponse?.signingStatus === null && reqResponse?.result?.code !== '0000') {
        showToast('error', reqResponse?.result?.message || 'server error');
        showLoadMask(false);
    }
    // request
    // check the signing status with timer
    // download file
    if (reqResponse?.signingStatus === 'R' || reqResponse?.signingStatus === 'P' || reqResponse?.signingStatus === 'S') {
        showToast('info', i18n.t('signing_in_progress'));
        const checkStatus = async () => {
            try {
                let urlCheckParams = new URLSearchParams();
                urlCheckParams.append('token', token);
                urlCheckParams.append('download_id', download_id);
                urlCheckParams.append('app_signing', app_signing);

                const checkResponse = await Post(getRegionalURL(RegionalURL.checkDownloadsealedsignedapp, region), urlCheckParams)
                    .then((res) => res.json())
                    .catch(() => {
                        showToast('error', i18n.t('signing_request_failed'));
                        showLoadMask(false);
                    });

                // stop checking if signing failed.
                if (checkResponse?.signingStatus === 'F') {
                    clearInterval(intervalId); // Stop the interval when you no longer need it
                    showToast('error', checkResponse.signingError);
                    showLoadMask(false);
                }

                // download if signingStatus is 'S'
                if (checkResponse?.signingStatus === 'S') {
                    clearInterval(intervalId); // Stop the interval when you no longer need it
                    if (checkResponse?.downloadEnv === 'on-premise') {
                        let popup = window.open(urlDownload, '_blank');
                        if (popup === null || typeof popup === 'undefined') {
                            onPopupError(urlDownload);
                        } else {
                            popup.focus();
                            if (setDownloadSuccess) setDownloadSuccess(true);
                            showToast('info', i18n.t('signing_completed'));
                        }
                    } else {
                        let popup = window.open(checkResponse?.s3DownLoadURL, '_blank');
                        if (popup === null || typeof popup === 'undefined') {
                            onPopupError(checkResponse?.s3DownLoadURL);
                        } else {
                            popup.focus();
                            if (setDownloadSuccess) setDownloadSuccess(true);
                            showToast('info', i18n.t('signing_completed'));
                        }
                    }

                    showLoadMask(false);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        const intervalId = setInterval(checkStatus, 30000); // 30000 milliseconds = 30 seconds
    }
};

export const uploadSealedApp = async (params: { token: string; packId: string }) => {
    const { token, packId } = params;
    const header = {
        Accept: 'application/json',
        'as-token': token,
    };
    const url = `${AppURL.preset}?packId=${packId}`;
    try {
        const response = await Get(url, null, header);
        return await response.json();
    } catch (error) {
        return false;
    }
};

export const addAndroidProject = async (params: createNewAndroidApp, token: string) => {
    const body = JSON.stringify(params);
    const header = {
        'Content-Type': 'application/json',
        'As-Token': token,
    };

    try {
        const response = await Post(AppURL.application, body, header);
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
};

export const getPresets = async (token: string) => {
    let url = `${AppURL.sealingPreset}`;
    const header = {
        Accept: 'application/json',
        'as-token': token,
    };

    const response = await Get(url, null, header)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const deletePreset = async (presetId: string, token: string) => {
    const url = `${AppURL.sealingPreset}${presetId}`;
    const header = {
        'as-token': token,
    };
    const response = await Delete(url, null, header)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};
export const savePreset = async (args: any, token: string) => {
    const header = {
        'Content-Type': 'application/json',
        'as-token': token,
    };
    const url = AppURL.sealingPreset;
    const response = await Post(url, JSON.stringify(args), header)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const editPreset = async (args: any, token: string) => {
    const header = {
        'Content-Type': 'application/json',
        'as-token': token,
    };
    const url = AppURL.sealingPreset;
    const response = await Put(url, JSON.stringify(args), header)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

export const copyPreset = async (args: any, presetId: string, token: string) => {
    const header = {
        'Content-Type': 'application/json',
        'as-token': token,
    };
    const url = `${AppURL.sealingPreset}/${presetId}/copy`;
    const response = await Post(url, JSON.stringify(args), header)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};

const onPopupError = (urlDownload: string | undefined) => {
    errorDialog({
        title: i18n.t('popup_error_title'),
        content: (
            <div>
                <span>{i18n.t('popup_error_message')}</span>
                <a className="text-linkblue hover:underline" href={urlDownload} download>
                    {i18n.t('popup_error_message2')}.
                </a>
            </div>
        ),
        okText: i18n.t('ok'),
    });
};

const onDownloadError = () => {
    errorDialog({
        title: i18n.t('error'),
        content: (
            <div>
                <span>{i18n.t('SCM-6041')}</span>
            </div>
        ),
        okText: i18n.t('ok'),
    });
};


export const editAndroidAppName = async (params: UpdateAndroidAppNameInterface) => {
    const { packageName, updatedAppName, token } = params;
    var updatedAppNameEncoding = updatedAppName;
    if(updatedAppNameEncoding.includes('+')) {
        updatedAppNameEncoding = updatedAppNameEncoding.replace(/\+/g, '%2B');
    }
    const url = `${RegionalURL.updateAndroidAppName}?packageName=${packageName}&updatedAppName=${updatedAppNameEncoding}`
    const header = {
        'Content-Type': 'application/json',
        'as-token': token,
    };
    const response = await Put(url, null, header)
    .then((res) => res.json())
    .catch(() => {});
    return response;
};

export const checkMemberPermission = async (params: { token: string; packName: string }) => {
    const { token, packName } = params;
    const url = `${AppURL.groups}/check-permission?package_name=${packName}`;

    const header = {
        'as-token': token,
    };

    const response = await Get(url, null, header)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};


export const checkPermissionOnUpload = async (token: string, apkfiles: File[]) => {
    const header = {
        'as-token': token,
    };
    let urlParams = new FormData();

    apkfiles.forEach(file => {
        urlParams.append('apkfile', file);
    });
   
    const url = `${AppURL.groups}/check-permission`;
    const response = await FilePost(url, urlParams, header)
        .then((res) => res.json())
        .catch(() => {});
    return response;
};
