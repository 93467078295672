import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentLayout from '../../../Layout/Content';
import { InputAdornment, TextField, Button, Chip } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import DataTable from '../../../DataDisplay/Table';
import { GridColDef } from '@mui/x-data-grid';

import LogDrawer from './LogDrawer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { getUserLogs } from '../../../../services/manage/userlog';

import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Moment } from 'moment';
import { Link } from 'react-router-dom';

interface ResponseInterface {
    result: {
        code: string;
        msg: string;
    };
    data: {
        apiHistories: {
            groupList: string[];
            koreanName: string;
            preHandleTime: string;
            userName: string;
        };
    };
}

const disabledDate = (current: any) => {
    return current && (current > moment().endOf('day') || current < moment().subtract(1, 'year'));
};

const defaultFromPeriod = moment().subtract(1, 'month');
const defaultToPeriod = moment();

const getChipColor = (status: string) => {
    const lowerStatus = status.toLowerCase();
    if (lowerStatus === 'login' || lowerStatus === '로그인') {
        return 'success';
    } else if (lowerStatus === 'information' || lowerStatus === '정보') {
        return 'info';
    } else {
        return 'secondary';
    }
};

const UserLog = () => {
    const { i18n, t } = useTranslation();
    const [searchValue, setSearchValue] = useState('');
    const [showMask, setShowMask] = useState<any>(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [jsonContent, setJsonContent] = useState({});
    const [userLogs, setUserLogs] = useState<any>([]);
    const [logId, setLogId] = useState<string | number | null>(null);
    const [fromPeriod, setFromPeriod] = useState<Moment | null>(defaultFromPeriod);
    const [toPeriod, setToPeriod] = useState<Moment | null>(defaultToPeriod);
    const [key, setKey] = useState<number>(0);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);

    useEffect(() => {
        handleUserLogs();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleUserLogs = async () => {
        setShowMask({ msg: t('fetching_user_log') });
        const startDate = fromPeriod ? fromPeriod.format('YYYY-MM-DD') : '';
        const endDate = toPeriod ? toPeriod.format('YYYY-MM-DD') : '';
        const response = (await getUserLogs({ token: authContext.token, startDate, endDate })) as unknown as ResponseInterface;
        if (response?.result?.code === '0000') {
            const apiHistories = Array.isArray(response?.data?.apiHistories) ? response.data.apiHistories : [];
            const apiHistoriesWithId = apiHistories.map((history: any, index: number) => ({ ...history, id: index }));
            setUserLogs(apiHistoriesWithId);
        }
        setShowMask(null);
    };
    const handleOpenDrawer = (json: string, logId: string | number) => {
        setJsonContent(json);
        setLogId(logId);
        setDrawerOpen(true);
    };

    const columns: GridColDef[] = [
        { field: 'userName', headerName: t('user_name'), flex: 1 },
        {
            field: 'groupList',
            headerName: t('group'),
            flex: 1,
            renderCell: (params) => {
                return params.row.groupList.length > 0
                    ? params.row.groupList.map((group: [string, string]) => (
                          <span className="mr-1">
                              [
                              <Link key={group[0]} to={`/organization/group/manage/sub?groupId=${group[0]}`} className="text-linkblue">
                                  {group[1]}
                              </Link>
                              ]
                          </span>
                      ))
                    : '-';
            },
        },
        { field: 'preHandleTime', headerName: t('last_active'), flex: 1 },
        {
            field: `${i18n.language === 'kr_KR' ? 'koreanName' : 'englishName'}`,
            headerName: t('status'),
            flex: 1,
            renderCell: (params) => <Chip label={t(params.value)} color={getChipColor(params.value)} />,
        },
        {
            field: 'code',
            headerName: t('action'),
            flex: 1,
            renderCell: (params) => <Button onClick={() => handleOpenDrawer(params.row, params.row.user_id)}>Check JSON</Button>,
        },
    ];

    const handleCloseDrawer = () => setDrawerOpen(false);

    const filterData = (data: any) => {
        const filteredList = data
            ? data.filter((group: any) => {
                  const groupNameMatch = group.userName.toLowerCase().includes(searchValue) || group.koreanName.includes(searchValue);
                  const groupManagerMatch = group.groupList ? group.groupList.join('').toLowerCase().includes(searchValue) : false;
                  return groupNameMatch || groupManagerMatch;
              })
            : [];

        return filteredList;
    };

    const onSearch = (value: string) => {
        setSearchValue(value.toLowerCase());
    };

    const handleSearch = () => {
        handleUserLogs();
    };
    return (
        <ContentLayout fullWidth={true} showMask={showMask}>
            <LogDrawer open={drawerOpen} onClose={handleCloseDrawer} jsonContent={jsonContent} logId={logId} />
            <div className="flex flex-wrap gap-6 mb-8 lg:flex-nowrap">
                <div className="text-2xl font-medium lg:min-w-[274px]">
                    <span className="text-2xl font-medium whitespace-nowrap">{t('user_log')}</span>
                </div>
                <div className="flex items-center w-full gap-4">
                    <div className="w-full lg:mt-0">
                        <TextField
                            id="search-input"
                            label="Search"
                            variant="outlined"
                            size="small"
                            fullWidth
                            placeholder={t('team_search_placeholder')}
                            onChange={(e) => onSearch(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon fontSize="small" />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <div className="w-full">
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <div className="flex gap-4 ">
                                <DatePicker
                                    className="w-1/3"
                                    label={t('from_date')}
                                    value={fromPeriod}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            fullWidth: true,
                                            inputProps: { disabled: false },
                                        },
                                    }}
                                    onChange={(date) => {
                                        setFromPeriod(date);
                                        setKey(key + 1);
                                    }}
                                    disableFuture
                                    shouldDisableDate={disabledDate}
                                />
                                <DatePicker
                                    className="w-1/3 "
                                    key={key}
                                    label={t('to_date')}
                                    value={toPeriod}
                                    minDate={fromPeriod ?? undefined}
                                    slotProps={{
                                        textField: {
                                            size: 'small',
                                            fullWidth: true,
                                            inputProps: { disabled: false },
                                        },
                                    }}
                                    onChange={(date) => setToPeriod(date)}
                                    disableFuture
                                    shouldDisableDate={disabledDate}
                                />
                                <Button variant="contained" onClick={handleSearch}>
                                    {t('search')}
                                </Button>
                            </div>
                        </LocalizationProvider>
                    </div>
                </div>
            </div>

            <div className="overflow-auto">
                <div className="flex-grow pl-1 p-4 pt-1 min-w-[800px]">
                    <DataTable height={'100%'} columns={columns} filter={true} rows={filterData(userLogs) || []} pageSize={12} pageSizeOptions={[10, 12, 20, 30]} />
                </div>
            </div>
        </ContentLayout>
    );
};

export default UserLog;
