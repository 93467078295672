import * as React from 'react';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';

import TableRow from '@mui/material/TableRow';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import moment from 'moment';
import { getLocalisedDate } from '../../../utils';
import { useTranslation } from 'react-i18next';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

interface RowProps {
    row: any;
    index: number;
    onMenuClick: (key: string, row: any) => void;
    expanded?: boolean;
}

const HistoryRow = ({ row, index, onMenuClick, expanded = false }: RowProps) => {
    const [open, setOpen] = React.useState(expanded);
    const { t } = useTranslation();
    return (
        <React.Fragment key={index}>
            <TableRow className={`cursor-pointer ${open ? 'bg-[#F3EDF6]' : ''}`} sx={{ '& > *': { borderBottom: 'unset' } }} onClick={() => setOpen(!open)}>
                <TableCell>
                    <div className="min-h-[50px] flex items-center">
                        {row.sealed_date ? moment(getLocalisedDate(moment(row.sealed_date, 'YYYYMMDDHHmmss').toDate())).format('YYYY/MM/DD HH:mm:ss') : ''}
                    </div>
                </TableCell>
                <TableCell>
                    <Chip
                        label={row.service_type === 'NATIVE_AOS' ? 'Native' : 'Hybrid'}
                        sx={{
                            color: '#fff',
                            backgroundColor: row.service_type === 'NATIVE_AOS' ? '#1FBF98' : '#B0A32F',
                        }}
                    />
                </TableCell>
                <TableCell>{row.package_name} {row.app_name? "("+row.app_name+")" : ""}</TableCell>
                <TableCell>{row.user_id}</TableCell>
                <TableCell>{row.option_appType}</TableCell>
                <TableCell>{row.core_version}</TableCell>
                <TableCell className={(row.status_code === '2' || row.status_code === '') ? 'text-accent' : 'text-warning'}>
                    {row.status_code === '3' && t('failed')}
                    {row.status_code === '2' && t('completed')}
                    {row.status_code === '1' && t('pending')}
                    {row.status_code === '0' && t('running')}
                    {row.status_code === '' && t('completed')}
                </TableCell>
                {!expanded && (
                    <TableCell>
                        {/* if sealed_date is less than a month ago from today show expired */}
                        {moment(row.sealed_date, 'YYYYMMDDHHmmss').isBefore(moment(new Date()).subtract(1, 'months')) ? (
                            <span className="text-warning">{t('expired')}</span>
                        ) : row.status_code === '2' ? (
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onMenuClick('download', row);
                                }}
                            >
                                <FileDownloadIcon />
                            </IconButton>
                        ) : (
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    onMenuClick('view_errors', row);
                                }}
                            >
                                <RemoveRedEyeIcon />
                            </IconButton>
                        )}
                    </TableCell>
                )}
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <div className="px-10 py-7">
                            <div className="font-semibold text-base mb-3">{t('service_version')}</div>
                            <div className="mb-7">
                                <ul className="list-none grid grid-cols-3 gap-x-10 p-0 m-0 text-sm text-darkgray">
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('status')}</span>
                                        <span className={`${row.status_code === '2' || row.status_code === '' ? 'text-accent' : 'text-warning'}`}>
                                            {row.status_code === '2' || (row.status_code === '' && t('completed'))}
                                            {row.status_code === '3' && t('failed')}
                                            {row.status_code === '0' && t('running')}
                                        </span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('platform')}</span>
                                        <span className="">
                                            <Chip
                                                label={row.service_type === 'NATIVE_AOS' ? 'Native' : 'Hybrid'}
                                                sx={{
                                                    color: '#fff',
                                                    backgroundColor: row.service_type === 'NATIVE_AOS' ? '#1FBF98' : '#B0A32F',
                                                }}
                                            />
                                        </span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('service_version')}</span>
                                        <span className="">{row.core_version}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('framework')}</span>
                                        <span className="">{row.hybrid_platform}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('package_name')}</span>
                                        <span className="">{row.package_name}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('app_name')}</span>
                                        <span className="">{row.app_name}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('user_id')}</span>
                                        <span className="">{row.user_id}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('file_name')}</span>
                                        <span className="">{row.original_file_name}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('sealed_date')}</span>
                                        <span className="">{row.sealed_date ? moment(row.sealed_date, 'YYYYMMDDHHmmss').format('YYYY/MM/DD HH:mm:ss') : ''}</span>
                                    </li>
                                    {row.sealingPresetName && (
                                        <li className="flex border-b border-[#E0E0E0] py-2 px-5">
                                            <span className="font-medium min-w-[125px]">{t('preset')}</span>
                                            <span className="">{row.sealingPresetName}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                            <div className="font-semibold text-base mb-3">{t('creation_info')}</div>
                            <div>
                                <ul className="list-none grid grid-cols-3 gap-x-20 p-0 m-0 capitalize">
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('sealing_mode')}</span>
                                        <span className="">{row.option_mode}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('app_category')}</span>
                                        <span className="">{row.option_appType}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('app_version')}</span>
                                        <span className="">{row.app_version}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('dex_encryption')}</span>
                                        <span className="">{row.option_dexEncrypt}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('rooting')}</span>
                                        <span className="">{row.option_rooting}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('emulator')}</span>
                                        <span className="">{row.option_emulator}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('work_profile')}</span>
                                        <span className="">{row.option_blockWorkProfile}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('macro_tools')}</span>
                                        <span className="">{row.option_macro}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('panel_attacks')}</span>
                                        <span className="">{row.option_sniff}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('keyloggers')}</span>
                                        <span className="">{row.option_blockKeyLogger}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('data_sealing')}</span>
                                        <span className="">{row.option_dataSealing === 'native_api' ? 'Yes' : row.option_dataSealing}</span>
                                    </li>
                                    <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                        <span className="font-medium min-w-[125px]">{t('over_the_air_update')}</span>
                                        <span className="">{row.option_useOtaService}</span>
                                    </li>
                                    {row.core_version?.split('.')?.slice(0, 2)?.join('.') >= '2.29' && (
                                        <React.Fragment>
                                            <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                                <span className="font-medium min-w-[125px]">{t('usb_debugging')}</span>
                                                <span className="">{row.block_usb_debugging === 'Yes' ? t('block') : t('allow')}</span>
                                            </li>
                                            <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                                <span className="font-medium min-w-[125px]">{t('developer_options')}</span>
                                                <span className="">{row.block_developer_options === 'Yes' ? t('block') : t('allow')}</span>
                                            </li>
                                            <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                                <span className="font-medium min-w-[125px]">{t('screen_capture')}</span>
                                                <span className="">{row.block_screen_capture === 'Yes' ? t('block') : t('allow')}</span>
                                            </li>
                                        </React.Fragment>
                                    )}
                                    {row.core_version?.split('.')?.slice(0, 2)?.join('.') < '2.29' && (
                                        <React.Fragment>
                                            <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                                <span className="font-medium min-w-[125px]">{t('mirroring_app')}</span>
                                                <span className="">{row.option_blockMirroring}</span>
                                            </li>
                                            <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                                <span className="font-medium min-w-[125px]">{t('remote_access_app')}</span>
                                                <span className="">{row.option_blockRemoteAccess}</span>
                                            </li>
                                        </React.Fragment>
                                    )}
                                    {row.core_version?.split('.')?.slice(0, 2)?.join('.') >= '2.30' && (
                                        <React.Fragment>
                                            <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                                <span className="font-medium min-w-[125px]">{t('overlay_windows')}</span>
                                                <span className="capitalize">{row.hide_overlay_windows}</span>
                                            </li>
                                        </React.Fragment>
                                    )}
                                    {row.core_version?.split('.')?.slice(0, 2)?.join('.') >= '2.33' && (
                                        <React.Fragment>
                                            {row?.dex_string_encrypt && (
                                                <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                                    <span className="font-medium min-w-[125px]">{t('dex_string_encrypt')}</span>
                                                    <span className="capitalize">{row.dex_string_encrypt}</span>
                                                </li>
                                            )}
                                            {row?.block_dex_dump && (
                                                <li className="flex border-b border-[#E0E0E0] py-2 gap-4 px-5">
                                                    <span className="font-medium min-w-[125px]">{t('block_dex_dump')}</span>
                                                    <span className="capitalize">{row.block_dex_dump}</span>
                                                </li>
                                            )}
                                        </React.Fragment>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export default HistoryRow;
