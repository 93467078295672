import { useEffect, useState } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import TagManager from 'react-gtm-module';

import store from './store';
import { Provider } from 'react-redux';
import { loadMessages, LocalizationProvider } from './hooks/localization';
import { Main } from './components/main';
import './App.less';
import { clarity } from 'react-microsoft-clarity';
import i18next from 'i18next';
import theme from './assets/styles/theme';

const tagManagerArgs = {
    gtmId: 'G-XM5S7W5492',
};

TagManager.initialize(tagManagerArgs);

const originalSetItem = localStorage.setItem;
localStorage.setItem = function (key, value) {
    if (key === 'user-lang') {
        value = value || 'en_US';
        let event = new CustomEvent('itemInserted', { detail: { key, value } });
        try {
            document.dispatchEvent(event);
        } catch (error) {
            value = 'en_US';
            event = new CustomEvent('itemInserted', { detail: { key, value } });
            document.dispatchEvent(event);
        } finally {
            originalSetItem.apply(this, [key, value]);
        }
    } else originalSetItem.apply(this, [key, value]);
};

const App = () => {
    const [locale, setLocale] = useState('');
    const key = window.location.host.split('.')[0] || '';
    if (key === 'localhost:3000') {
        console.log('Stop Clarity (Local)');
    } else if (key === 'console') {
        clarity.init('i7quedxx6t');
    } else if (key === 'tst-console') {
        console.log('Stop Clarity (TST)');
    } else if (key === 'dev-console') {
        console.log('Stop Clarity (DEV)');
    }

    // Check if Clarity has been initialized before calling its methods
    if (clarity.hasStarted()) {
        clarity.identify('USER_ID', { userProperty: 'value' });
    }
    useEffect(() => {
        if (window.location.host === 'developer.appsealing.com') window.location.href = 'https://console.appsealing.com/';
    }, []);

    useEffect(() => {
        if (locale === null || locale === '') {
            let messages,
                lang = localStorage.getItem('user-lang') || 'en_US';
            try {
                messages = require('./assets/localization/adc-' + lang + '.json');
            } catch (error) {
                lang = 'en_US';
                localStorage.setItem('user-lang', lang);
                messages = require('./assets/localization/adc-' + lang + '.json');
            } finally {
                loadMessages(messages, lang);
                setLocale(lang);
                i18next.changeLanguage(lang);
            }
        }
    }, [locale]);

    useEffect(() => {
        document.addEventListener(
            'itemInserted',
            ((e: CustomEvent) => {
                setLocale((oldLang) => {
                    let messages,
                        lang = e.detail?.value || 'en_US';
                    if (lang === oldLang) return oldLang;

                    try {
                        messages = require('./assets/localization/adc-' + lang + '.json');
                        loadMessages(messages, lang);
                    } catch (error) {
                        throw error;
                    }
                    return lang;
                });
            }) as EventListener,
            false,
        );
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <LocalizationProvider lang={locale}>
                    <Router>
                        <Main />
                    </Router>
                </LocalizationProvider>
            </Provider>
        </ThemeProvider>
    );
};

export default App;
