import React, { useEffect, useRef, useState } from 'react';
import { RootState } from '../../store';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useAuthorizationService } from '../../hooks/useAuthorizationService';
import { ProtectedRoutes, PublicRoutes } from '../../routes';
import { setAuthContext } from '../../store/main';
import { getCookies, setCookies } from '../../utility/Common';
import { ScrollToTop } from '../utils';
import LoadMask from '../utils/loadmask';
import './index.less';
import MainFooter from './mainfooter';
import MainHeader from './mainheader';
import Sider from './Sider';

const publicRoutes = ['signup', 'awssignup', 'helpdesk', 'confirmation', 'invites'];

export const Main = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(true);
    const authorizationService = useAuthorizationService();
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const planDetails = useSelector((state: RootState) => state.mainReducer.planDetails);
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const myDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (pathname === '/') {
            navigate('/dashboard');
        }
        if (myDivRef.current) {
            myDivRef.current.scrollTop = 0;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    useEffect(() => {
        if (authContext === null) {
            if (publicRoutes.includes(location.pathname.split('/')[1])) {
                dispatch(
                    setAuthContext({
                        isAuth: false,
                    }),
                );
            } else {
                dispatch(setAuthContext({}));
                try {
                    const cookie = getCookies('appsealing');
                    if (cookie) {
                        authorizationService.authorizeUser(cookie);
                    } else {
                        setCookies('appsealing', '');
                        localStorage.removeItem('okta-jwt');
                        dispatch(
                            setAuthContext({
                                isAuth: false,
                            }),
                        );
                    }
                } catch (error) {
                    setCookies('appsealing', '');
                    localStorage.removeItem('okta-jwt');
                    dispatch(
                        setAuthContext({
                            isAuth: false,
                        }),
                    );
                }
            }
        } else {
            if (pathname === '/login' && authContext?.isAuth) {
                navigate('/dashboard');
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authContext, authorizationService, location, dispatch]);

    const isAndroidExpired = () => {
        let isExpired = false;
        if (planDetails?.plan?.android?.type === 'T' && planDetails?.plan?.android?.isExpired && planDetails?.plan?.hybrid?.type === 'T' && planDetails?.plan?.hybrid?.isExpired) isExpired = true;
        return isExpired;
    };

    const isIOSExpired = () => {
        let isExpired = true;
        if (planDetails.plan?.ios?.type === 'E' || planDetails.plan?.ios?.type === 'P' || planDetails.plan?.hybrid?.type === 'E' || planDetails.plan?.hybrid?.type === 'P') isExpired = false;
        return isExpired;
    };

    const handleClick = () => setCollapsed(!collapsed);

    return (
        <React.Fragment>
            <ScrollToTop />
            {authContext?.isAuth && planDetails ? (
                <div className={`w-full max-h-screen h-screen overflow-hidden bg-secondary`} style={{ flexDirection: 'column' }}>
                    <MainHeader handleClick={handleClick} />
                    <div className="flex flex-row flex-grow mainview">
                        <Sider />
                        <div ref={myDivRef} className="w-full overflow-x-auto bg-secondary md:overflow-hidden md:overflow-y-auto" style={{ maxWidth: '100%', height: 'calc(100vh - 50px)' }}>
                            <ProtectedRoutes androidExpired={isAndroidExpired()} iOSExpired={isIOSExpired()} />
                            <MainFooter color={'primary'} />
                        </div>
                    </div>
                </div>
            ) : authContext?.isAuth === false ? (
                <PublicRoutes />
            ) : (
                <div>
                    <LoadMask msg={t('validating_user_information')}></LoadMask>
                </div>
            )}
        </React.Fragment>
    );
};
