import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuthorizationService } from '../../hooks/useAuthorizationService';
import { oktaCallback } from '../../services/login';
import { setCookies } from '../../utility/Common';
import LoadMask from '../utils/loadmask';
import { showDialog } from '../utils/notifications';

type DialogConfig = {
    success?: boolean;
    title?: string;
    content?: string;
    href?: string;
};

const OktaCallback = () => {
    const { t } = useTranslation();
    const authorizationService = useAuthorizationService();
    const [loadMask, showLoadMask] = useState(false);

    useEffect(() => {
        showLoadMask(true);

        let code: string | null = null;
        let state: string | null = null;

        const searchParams = new URLSearchParams(window.location.search);
        code = searchParams.get('code');
        state = searchParams.get('state');

        if (!code || !state) {
            const hashParams = new URLSearchParams(window.location.hash.replace('#', '').split('?')[1] || '');
            code = code || hashParams.get('code');
            state = state || hashParams.get('state');
        }

        if (!code || !state) {
            showDialog({
                title: t('failed_to_login_contact_help_desk_for_more_information'),
                content: t('failed_to_login_contact_help_desk_for_more_information'),
                okText: t('ok'),
                onOk: () => {
                    window.location.href = '/okta/lg-login';
                },
                cancelButtonProps: {
                    hidden: true,
                },
            });
            return;
        }

        const handleLoginResponse = async (code: string, state: string) => {
            const response = await oktaCallback(code, state);
            const resultCode = response?.result?.loginResultCode;
            const responseEmail = response?.result?.email;

            const dialogConfigs: Record<string, DialogConfig> = {
                '0000': {
                    success: true,
                },
                '1122': {
                    title: 'your_account_does_not_exist',
                    content: 'your_account_does_not_exist_please_sign_up',
                    href: `/#/signup?oktaemail=${responseEmail}`,
                },
                '2700': {
                    title: 'your_account_is_not_active',
                    content: 'your_account_is_not_active_please_contact_help_desk',
                },
                '2710': {
                    title: 'your_account_is_suspended',
                    content: 'your_account_is_suspended_please_contact_help_desk',
                },
            };

            const config = dialogConfigs[resultCode as keyof typeof dialogConfigs] || {
                title: 'failed_to_login',
                content: 'failed_to_login_contact_help_desk_for_more_information',
            };

            showLoadMask(false);

            if (config.success) {
                setCookies('appsealing', response.result.jwt);
                localStorage.setItem('okta-jwt', response.result.jwt);
                authorizationService.authorizeUser(response.result.jwt, showLoadMask);
                window.location.href = '/';
                return;
            }

            showDialog({
                title: t(config.title || 'error'),
                content: t(config.content || 'error'),
                okText: t('ok'),
                cancelButtonProps: { hidden: true },
                onOk: () => (window.location.href = config.href || '/okta/lg-login'),
            });
        };

        handleLoginResponse(code, state);
    }, []);

    return <>{loadMask && <LoadMask msg={t('logging_in')} />}</>;
};

export default OktaCallback;
