import React, {useState} from 'react';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Switch from '@mui/material/Switch';
import DropdownMenu from '../../../../utils/dropDownMenu';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { editAndroidAppName } from '../../../../../services/sealing/android';
import { Button, Icon, IconButton, TextField } from '@mui/material';
import { showToast } from "../../../../utils/notifications";

interface AndroidCardInterface {
    app_name: string;
    package_name: string;
    service_type?: string;
    service_onoff: string;
    use_data_sealing: string;
    use_ota_service: string;
    custom_blacklist: string;
    service_mod_user_id?: string;
    service_mod_date?: string;
    onToggleSwitch: (type: string, params: any, cardDetails: any) => void;
    onButtonClick: (type: string, cardDetails: any) => void;
    handleSelectApp?: (app: any) => void;
    
}
 

const AndroidServiceCard = (props: AndroidCardInterface) => {
    const { t } = useTranslation();
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const isBetaAccount = authContext?.is_beta_account;
    const isOwner = authContext?.role === 'Owner' || authContext?.role === 'SubOwner';
    const isManager = authContext?.is_group_manager;

    const [tempAppName, setTempAppName] = useState(props.app_name);
    const [tempEdit, setTempEdit] = useState(false);

    const starteditAndroidAppName = () => {
        setTempEdit(true);
    };
    const updateAppName = async (props: AndroidCardInterface, tempAppName: string) => {
        setTempEdit(false);
        const response = await editAndroidAppName({packageName:props.package_name, updatedAppName:tempAppName,token:authContext.token});
        if (response?.result?.code === "0000") {
                        setTempEdit(false);
                        showToast("success", t("app_name_updated_successfully"));
                        window.location.reload(); // Reload the page to reflect the updated app name
                    } else if (response?.result?.code) {
                        showToast(
                        "error",
                        t(
                            `RCM-${response?.result?.code}`,
                            response?.result?.message
                            ? response?.result?.message
                            : t("error_occurred")
                        ) as string
                        );
                    } else {
                        showToast("error", t("error_occurred"));
                    }

    }

    const onServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const params = {
            api_ver: '4',
            platform: 'android',
            token: authContext.token,
            package_name: props.package_name,
            status: e.target.checked === true ? 'Y' : 'N',
        };
        props.onToggleSwitch('service', params, { ...props });
    };

    const onDataSealingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const params = {
            package_name: props.package_name,
            request: e.target.checked === true ? 'Y' : 'N',
            service_id: 'DATA_SEALING',
            token: authContext.token,
        };
        props.onToggleSwitch('datasealing', params, { ...props });
    };

    const onCustomBlacklistChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const params = {
            package_name: props.package_name,
            request: e.target.checked === true ? 'Y' : 'N',
            service_id: 'OTA_SERVICE',
            token: authContext.token,
        };
        props.onToggleSwitch('otaservice', params, { ...props });
    };
    const onCustomBlacklistClick = () => props.onButtonClick('customblacklist', { ...props });
    const handleMenuChange = (type: string) => {
        if (props.handleSelectApp) {
            props.handleSelectApp({ ...props });
        }
    };
    return (
        <Card key={props.package_name} className="w-full max-w-full lg:max-w-[316px]">
            <CardHeader title={
                <div className="flex items-center justify-between flex-none">
                {tempEdit ? (
                    <div className="flex items-center">
                    <IconButton
                        className="ml-2"
                        onClick={() => updateAppName(props, tempAppName)}
                    >
                        <Icon sx={{ fontSize: 15 }} component={CheckIcon} />
                    </IconButton>
                    <IconButton className="ml-2" onClick={() => setTempEdit(false)}>
                    <Icon sx={{ fontSize: 15 }} component={ClearIcon} />
                    </IconButton>
                        <TextField
                            className="w-40"
                            value={tempAppName}
                            fullWidth
                            onChange={(e) => setTempAppName(e.target.value)}
                        />
                    </div>
                ) : (
                    <span className="text-base font-semibold truncate text max-w-[150px]">
                        {(isOwner)?<IconButton onClick={() => setTempEdit(true)}>
                                    <Icon sx={{ fontSize: 15 }} component={EditIcon} />
                                 </IconButton>: null}
                        <span>{props.app_name}</span>
                    </span>
                )}
                <DropdownMenu menuOptions={[{ value: 'settings', label: 'settings' }]} handleMenuChange={handleMenuChange} />
            </div>
            } />
            <CardContent>
                <Tooltip title={props.package_name}>
                    <p className="flex justify-between text-sm" style={{ wordBreak: 'keep-all' }}>
                        <span className="mr-2 font-semibold">{t('package')} </span>
                        <span className="truncate">{props.package_name}</span>
                    </p>
                </Tooltip>
                <p className="flex justify-between mt-3 text-sm">
                    <span className="flex items-center font-semibold">{t('service')} </span>
                    <Switch disabled={!isOwner} checked={props.service_onoff === 'Y'} onChange={onServiceChange} />
                </p>
                <p className="flex justify-between mt-3 text-sm">
                    <span className="flex items-center font-semibold">{t('data_sealing')} </span>
                    <Switch disabled={isBetaAccount ? !(isOwner || isManager) : false} checked={props.use_data_sealing === 'Y'} onChange={onDataSealingChange} />
                </p>
                <p className="flex justify-between mt-3 text-sm">
                    <span className="flex items-center">
                        <span className="mr-1 font-semibold">{t('custom_blacklist')} </span>
                        {props.use_ota_service === 'Y' && (
                            <Tooltip title={t('manage_custom_blacklist')}>
                                <DriveFileRenameOutlineIcon fontSize={'small'} onClick={onCustomBlacklistClick} />
                            </Tooltip>
                        )}
                    </span>
                    <span>
                        <Switch disabled={isBetaAccount ? !(isOwner || isManager) : false} checked={props.use_ota_service === 'Y'} onChange={onCustomBlacklistChange} />
                    </span>
                </p>
            </CardContent>
        </Card>
    );
};

export default AndroidServiceCard;
