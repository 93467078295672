import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openHelpCenter } from '../../../../../services/main';
import { checkMemberPermission, downloadSealedApp, downloadSealedSignedApp, getKeyStoreInfo } from '../../../../../services/sealing/android';
import { RootState } from '../../../../../store';
import { AppURL } from '../../../../../utility/AppURL';
import LoadMask from '../../../../utils/loadmask';
import ManageKeyStore from '../../managekeystore';
import SuccessModal from '../../sealing/modals/successModal';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogContent, DialogTitle, Tooltip, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { setKeyStoreInfo } from '../../../../../store/sealing/android';

interface DownloadModalInterface {
    data: any;
    visible: boolean;
    onCancel: () => void;
}

const DownloadModal = (props: DownloadModalInterface) => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const [isAAB, setIsAAB] = useState(false);
    const [loadMask, showLoadMask] = useState<any>(false);
    const region = useSelector((state: RootState) => state.mainReducer.region);
    const authContext = useSelector((state: RootState) => state.mainReducer.authContext);
    const keyStoreInfo = useSelector((state: RootState) => state.androidSealingReducer.keyStoreInfo);
    const [disableKeystore, setDisableKeystore] = useState(false);
    const [packageName, setPackageName] = useState<string | null>(null);
    const [downloadSuccess, setDownloadSuccess] = useState(false);
    const isBetaAccount = authContext?.is_beta_account;
    const isMember = authContext?.role === 'Member';
    useEffect(() => {
        if (props.data) {
            setIsAAB((props.data?.original_file_name || '').toLowerCase().endsWith('.aab'));
            setPackageName(props.data.package_name);
        }
    }, [props.data]);

    const checkMemberPermissionResult = useCallback(async () => {
        const response = await checkMemberPermission({ token: authContext.token, packName: props.data.package_name });
        if (response.result.code === '0000') {
            if (!response.data.keyStoreUsed) {
                setDisableKeystore(true);
            }
        }
    }, [authContext.token, props]);

    useEffect(() => {
        if (isBetaAccount && props.data && isMember) {
            checkMemberPermissionResult();
        }
    }, [isBetaAccount, props, isMember, authContext.token, checkMemberPermissionResult]);

    useEffect(() => {
        const fetchKeyStoreInfo = async () => {
            let params: any = {
                token: authContext.token,
                account_status: authContext.account_status,
                account_type: authContext.account_type,
                auth_key: authContext.auth_key,
                role: authContext.role,
                user_status: authContext.user_status,
            };

            if (isBetaAccount && props.data.package_name) {
                params['packageName'] = props.data.package_name;
            }

            const response = await getKeyStoreInfo(params, region);
            if (response?.result?.code === '0000') dispatch(setKeyStoreInfo(response?.result?.keystore));
        };

        if (isBetaAccount && props.visible) fetchKeyStoreInfo();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region, authContext, dispatch, isBetaAccount, props.visible]);

    const onDownloadWithoutSigning = () => {
        const params = {
            token: authContext.token,
            download_id: props.data.download_id,
        };
        downloadSealedApp(params, region, setDownloadSuccess);
    };

    const onDownloadSignedApp = async (app_signing: string) => {
        showLoadMask({ msg: t('app_signing') });
        const params = {
            token: authContext.token,
            download_id: props.data.download_id,
            app_signing: app_signing,
        };
        downloadSealedSignedApp(params, region, showLoadMask, setDownloadSuccess);
    };

    const onHelpCenterClick = () => {
        const redirectURL = AppURL.helpCenter + (i18n.language === 'en_US' ? 'en-us' : 'ko') + '/articles/900001407366';
        openHelpCenter(authContext.token, redirectURL);
    };

    const customHeader = (
        <div className="flex justify-between">
            <div>
                <Typography variant="h1">{t('download_sealed_application')}</Typography>
                <p className="text-xs">{t('application_is_sealed_ready')}</p>
            </div>
            <div className="cursor-pointer">
                <CloseIcon onClick={props.onCancel} />
            </div>
        </div>
    );

    return (
        <>
            {downloadSuccess && <SuccessModal downloadSuccess={downloadSuccess} setDownloadSuccess={setDownloadSuccess} />}
            <Dialog
                open={props.visible}
                onClose={props.onCancel}
                maxWidth="sm"
                sx={{
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                            width: '100%',
                            maxWidth: '520px',
                        },
                    },
                }}
            >
                <DialogTitle id="custom-dialog-title">{customHeader}</DialogTitle>
                <DialogContent className="pr-2 my-10">
                    {loadMask && <LoadMask msg={loadMask.msg} className="fixed top-0 left-0 w-full h-full" />}
                    <div className="mb-10">
                        <Accordion sx={{ boxShadow: 'none', '&::before': { display: 'none' } }}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="p-0 m-0">
                                <label className="mb-1 text-lg font-medium">{t('download_without_signing')}</label>
                            </AccordionSummary>
                            <AccordionDetails className="p-0 m-0">
                                <div className="mx-5 mb-9">
                                    <p>
                                        {t(`${isAAB ? 'aab' : 'apk'}_download_without_signing_note_1`)}
                                        <span className="text-red-500">{t(`${isAAB ? 'aab' : 'apk'}_download_without_signing_note_2`)}</span>
                                        {t(`${isAAB ? 'aab' : 'apk'}_download_without_signing_note_3`)}
                                        <span className="ml-0 underline cursor-pointer sm:ml-2 text-linkblue" onClick={onHelpCenterClick}>
                                            '{t(`${isAAB ? 'aab' : 'apk'}_download_without_signing_note_4`)}
                                            '.
                                        </span>
                                    </p>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        <Button fullWidth variant="outlined" color="primary" onClick={onDownloadWithoutSigning}>
                            {t('download')}
                        </Button>
                    </div>
                    {isAAB !== true && (
                        <div className="mb-10">
                            <Accordion sx={{ boxShadow: 'none', '&::before': { display: 'none' } }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} className="p-0 m-0">
                                    <label className="mb-1 text-lg font-medium">{t('download_signed_app_with_appsealing_key')}</label>
                                </AccordionSummary>
                                <AccordionDetails className="p-0 m-0">
                                    <div className="mb-9">{t('download_signed_app_with_appsealing_key_note')}</div>
                                </AccordionDetails>
                            </Accordion>
                            <Button fullWidth variant="outlined" color="primary" onClick={() => onDownloadSignedApp('APPSEALING_KEY')}>
                                {t('download')}
                            </Button>
                        </div>
                    )}
                    <div className="mt-2">
                        <Tooltip title={disableKeystore ? t('you_do_not_have_permission_to_use_app_signing_key') : ''}>
                            <Accordion sx={{ boxShadow: 'none', '&::before': { display: 'none' } }} disabled={disableKeystore}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} className="p-0 m-0">
                                    <label className="mb-1 text-lg font-medium">{(isAAB ? '2. ' : '3. ') + t('download_signed_app_with_your_key')}</label>
                                </AccordionSummary>
                                <AccordionDetails className="p-0 m-0">
                                    <div className="mb-9">
                                        {t(`${isAAB ? 'aab' : 'apk'}_download_signed_app_with_your_key_note`)}
                                        <div className="my-10 ml-2">
                                            <ManageKeyStore showTitle={false} endBtn={true} packageName={packageName} />
                                        </div>
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </Tooltip>
                        <Button variant="outlined" color="primary" fullWidth disabled={!keyStoreInfo} onClick={() => onDownloadSignedApp('REGISTERED_KEY')}>
                            {t('download')}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default DownloadModal;
