import { Chip } from '@mui/material';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../store';
import { setBadgeCount } from '../../store/common';
import { NotificationsListType, NotificationsTagsType } from '../../type';
import { getColorForTag } from '../utils/common';
import './item.less';
interface AnnouncementItemProps {
    item: NotificationsListType;
    locale: string;
    tags: NotificationsTagsType[];
}

const AnnouncementItem = ({ item, locale, tags }: AnnouncementItemProps) => {
    const title = item[locale === 'kr_KR' ? 'kr_title' : 'en_title'];
    const content = removeHtmlTagsFromEncodedString(item[locale === 'kr_KR' ? 'kr_contents' : 'en_contents']);
    const textStyle = item.read ? { color: '#C2C2C2' } : { color: '#505050' };
  
    const dispatch = useDispatch();
    const badgeCount = useSelector((state: RootState) => state.commonReducer.badgeCount);

    function decodeHtmlEntities(encodedString: string): string {
        const textArea = document.createElement('textarea');
        textArea.innerHTML = encodedString;
        return textArea.value;
    }

    function removeHtmlTagsFromEncodedString(encodedString: string): string {
        const decoded = decodeHtmlEntities(encodedString); // Decode entities
        return decoded.replace(/<\/?[^>]+(>|$)/g, ''); // Remove HTML tags
    }

    const markAsRead = () => {
        const isRead = localStorage.getItem('isRead');
        if (!isRead) {
            localStorage.setItem('isRead', `[${item.seq}]`);
            dispatch(setBadgeCount(badgeCount ? badgeCount - 1 : 0));
        } else {
            const isReadJson = JSON.parse(isRead);
            if (!isReadJson.includes(item.seq)) {
                let addIsRead = [...isReadJson, item.seq];
                localStorage.setItem('isRead', JSON.stringify(addIsRead));
                dispatch(setBadgeCount(badgeCount ? badgeCount - 1 : 0));
            }
        }
    };

    return (
        <Link to={`/announcement/${item.seq}`} className="flex w-full" onClick={() => markAsRead()}>
            <span className={`badge-placeholder ${!item.read || item.read === undefined ? 'unread-badge' : 'menu-read-badge'}`}></span>
            <div className="w-full">
                <p className="text-sm font-semibold whitespace-pre-wrap break-all line-clamp-1 w-full" style={textStyle}>
                    {title}
                </p>
                <p className="text-xs line-clamp-2 break-all whitespace-pre-wrap" style={textStyle}>
                    {content}
                </p>
                <div className="flex flex-row gap-1 mt-2 justify-between w-full items-center">
                    <div>
                        {item.ntypescode.map((code) => {
                            const ntag = tags.find((tag) => code === `${tag.seq}`);
                            return ntag ? (
                                <Chip key={ntag.tag_en} sx={{ backgroundColor: getColorForTag(ntag.color) }} className="max-h-6 mr-1" label={ntag[locale === 'kr_KR' ? 'tag_kr' : 'tag_en']} />
                            ) : null;
                        })}
                    </div>
                    <span className="text-10px font-medium leading-4 text-right ml-2 text-primarymax-h-6" style={textStyle}>
                        {moment(item.modify_date_format, 'YYYYMMDD').fromNow()}
                    </span>
                </div>
            </div>
        </Link>
    );
};

export default AnnouncementItem;
