import { handleErrorResponse } from './errorHandling';
import { getApiUrls } from './getApiUrls';
import { proxy } from './proxy';

interface CustomResponse extends Response {
    result?: { code: string };
}
interface CustomFetchOptions extends RequestInit {
    skipErrorDialog?: boolean;
}

export const customFetch = async (url: string, options: CustomFetchOptions = {}): Promise<CustomResponse> => {
    try {
        const errorCode = [401, 403, 504, 503, '401', '403', '504'];
        const response: CustomResponse = await fetch(getURL(url), options);
        if (errorCode.includes(response.status)) {
            handleErrorResponse(response.status);
            throw new Error('Response not OK');
        } else {
            const contentType = response.headers.get('Content-Type');
            if (contentType && !contentType.includes('application/json')) {
                return response;
            }
            let data = response.clone();
            data.json().then((res) => {
                if (res?.result?.code === '4000' || res?.result?.code === 4000) {
                    handleErrorResponse(res?.result?.code);
                    throw new Error();
                }
            });
        }
        return response;
    } catch (error) {
        handleErrorResponse(null);
        throw error;
    }
};

export const fetchWithMethod =
    (method: string) =>
    async (url: string, params: any = {}, headers: HeadersInit = {}, skipErrorDialog: boolean = false): Promise<Response> => {
        const jwt = localStorage.getItem('okta-jwt');
        let defaultHeaders: HeadersInit = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        if (jwt) {
            defaultHeaders['Authorization'] = `Bearer ${jwt}`;
        }

        const options: RequestInit = {
            method,
            headers: { ...defaultHeaders, ...headers },
            body: undefined,
        };
        if (method === 'GET' || method === 'HEAD') {
            options.headers = headers || {
                'Content-Type': 'text/plain',
            };
        }

        if (params && method !== 'GET' && method !== 'HEAD') {
            options.body = params;
        }
        return customFetch(getURL(url), { ...options, skipErrorDialog });
    };

export const getURL = (url: string): string => {
    const { apiUrl, inApiUrl } = getApiUrls();
  
    let targetServer: string = apiUrl;
    let targetInServer: string = inApiUrl;

    let newURL = url;
    let query = '';
    const isProxy = url[0] === '/';
    let urlParts = url.split('/');
    urlParts.shift();
    if (isProxy && !url.startsWith('/assetsvc')) {
        if (urlParts[0].includes('?')) {
            [urlParts[0], query] = urlParts[0].split('?');
            query = '?' + query;
        }

        urlParts[0] = urlParts[0].endsWith('-in') ? targetInServer + proxy[urlParts[0].split('-')[0] as keyof typeof proxy] + query : targetServer + proxy[urlParts[0] as keyof typeof proxy] + query;
        newURL = urlParts.join('/');
    } else if (url.startsWith('/assetsvc')) {
        newURL = url.replace('/assetssvc', proxy['assetsvc']);
    } else {
        newURL = url;
    }

    return newURL;
};
